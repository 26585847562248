<template>
    <div class="container">
        <h1>{{ $t('realOwners.heading') }}</h1>
        <div class="p-4 bg-white border-light rounded shadow">
            <h5>{{ $t('realOwners.subHeading') }}</h5>
            <form-input-radio-group
                :label="$t('realOwners.realOwnersDeterminable')"
                v-model="undeterminable"
                :options="yesNoOptionsReversed"
                :stacked="false"
                class="my-5"
            />
            <real-owner-text
                class="small"
                :par-number-class="'text-black'"
                allow-truncate
                hide-heading
            />
        </div>
        <div class="mt-5">
            <div class="mt-5" v-if="realOwners.length > 0">
                <h4>{{$t('realOwners.addedPeople')}}</h4>
                <real-owners-list
                    :people="realOwners"
                    @edit="onEditPerson"
                    @delete="onDeletePerson"
                />
            </div>

            <div
                v-if="!showForm"
                class="mt-4"
            >
                <b-btn
                    variant="primary"
                    @click.prevent="showForm = true"
                >
                    <b-icon icon="plus" />{{ $t('realOwners.addPerson') }}
                </b-btn>
            </div>
            <div ref="realOwnerFormDiv">
                <div v-if="showForm" class="mt-5">
                    <h3>{{$t(`realOwners.form.${editedRealOwner ? 'edit' : 'create'}`)}}</h3>
                    <div class="p-4 bg-white border-light rounded shadow">
                        <real-owner-form
                            :real-owner-undeterminable="undeterminable"
                            :defaults="editedRealOwner || {}"
                            :date-picker-lang="datePickerLang"
                            :real-owner-types="realOwnerTypes"
                            :show-validation="touchForm"
                            @submit="onSubmitRealOwner"
                            @cancel="onCancel"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RealOwnerForm from '../../../RealOwners/Components/RealOwnerForm.vue'
import RealOwnersList from '../../../RealOwners/Components/RealOwnersList.vue'
import ViewBase from '../../../ViewBase.vue'
import RealOwnerText from '../../../RealOwners/Components/RealOwnerText.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'RealOwners',
    extends: ViewBase,
    components: {
        RealOwnerForm,
        RealOwnersList,
        RealOwnerText
    },
    data () {
        return {
            editedRealOwner: null,
            showForm: !(this.sessionData && Array.isArray(this.sessionData.realOwners) && this.sessionData.realOwners.length > 0),
            touchForm: false
        }
    },
    computed: {
        ...mapGetters(['realOwnerTypes']),
        yesNoOptionsReversed () {
            return [{ value: false, text: this.$t('app.yes') }, { value: true, text: this.$t('app.no') }]
        },
        undeterminable: {
            get () {
                return this.sessionData?.realOwnerUndeterminable ?? false
            },
            set (value) {
                this.saveAmlSessionData({ realOwnerUndeterminable: value })
            }
        },
        companyOwners () {
            return ((this.sessionData) ? this.sessionData.companyPeople.filter(item => item.executive) : []) || []
        },
        realOwners () {
            return ((this.sessionData) ? this.sessionData.realOwners : []) || []
        }
    },
    mounted () {
        this.$serverValidator.addHandler('realOwners', this.onServerValidation)
        this.$nextTick(() => {
            this.$store.commit('setFormData', { name: 'realOwners', content: this.realOwners })
        })
    },
    beforeDestroy () {
        this.$serverValidator.removeHandler('realOwners', this.onServerValidation)
        this.touchForm = false
    },
    methods: {
        onServerValidation (isValid, ownerId) {
            if (!isValid) {
                const owner = this.realOwners.find(item => item._id === ownerId)
                this.onEditMissingProps(owner)
                this.touchForm = true
            }
        },
        async onSubmitRealOwner (data) {
            this.companyOwnersSaving = true
            const api = this.$api.amlSessionRealOwners
            try {
                const apiCall = (id, data) => (id) ? api.update(id, data) : api.create(data)
                await apiCall(data._id || null, data)
                this.editedRealOwner = null
                this.showForm = false
                this.$store.commit('setDataHash', { name: 'realOwnerOld', hash: null })
                this.$store.commit('setDataHash', { name: 'realOwner', hash: null })
                this.$emit('reload')
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.sessionUpdateFailed'))
            } finally {
                this.$nextTick(() => {
                    this.companyOwnersSaving = false
                })
            }
        },
        onCancel () {
            this.showForm = false
            this.editedRealOwner = null
            this.touchForm = false
        },
        onEditPerson (person) {
            this.onCancel()
            this.$nextTick(() => {
                this.editedRealOwner = { ...person }
                this.showForm = true
            })
        },
        onDeletePerson (person) {
            this.companyOwnersSaving = true
            this.$api.amlSessionRealOwners.delete(person._id)
                .then(() => {
                    this.$emit('reload')
                }).catch((error) => {
                    console.error(error.message)
                }).finally(() => {
                    this.$nextTick(() => {
                        this.companyOwnersSaving = false
                    })
                })
        },
        onEditMissingProps (owner) {
            this.$notify.error(this.$t('errors.someDataIsMissing'))
            this.onEditPerson(owner)
            this.$nextTick(() => {
                const form = this.$refs.realOwnerFormDiv
                this.$nextTick(() => {
                    if (form) {
                        form.scrollIntoView({ behavior: 'smooth' })
                    }
                })
            })
        }
    }
}
</script>
